<template>
  <div class="PlayerStatus">
    <BreadcrumbVue :navigatelist="navigatelist"/>
    <div class="tablewrap">
      <el-table :data="tableData" @sort-change="handleSortChange" style="width: 100%" empty-text="还没有数据哦~" stripe>
        <el-table-column
            v-for="(item, index) in tablecolumn" :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="item.prop=='statusselect'">
              <el-select v-model="status" placeholder="请选择游戏" style="marginLeft:20px" >
                <el-option
                    label="普通"
                    :value="0"
                />
                <el-option
                    label="黑名单"
                    :value="1"
                />
              </el-select>
            </div>
            <div v-else-if="item.prop=='roleselect'">
              <el-select v-model="role" placeholder="请选择等级" style="marginLeft:20px" >
                <el-option
                    label="普通"
                    :value="0"
                />
                <el-option
                    label="J-Gamer"
                    :value="1"
                />
              </el-select>
            </div>
            <div
                v-else-if="scope.row[item.prop]!=0&&!scope.row[item.prop]"
            >-
            </div>
            <div
                v-else-if="item.prop == 'status'"
            >{{scope.row[item.prop]==0?'正常':'黑名单'}}
            </div>
            <div v-else>{{scope.row[item.prop]}}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="btn">
      <el-button type="primary" @click="handleupdate">确定</el-button>
      <el-button  @click="handlecancel">取消</el-button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue"
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router"
import { post } from "../../utils/request"
import { ElMessage } from "element-plus"
import { Decrypt } from "../../utils/secret"
import { oncePress } from "../../utils/limitClick"
const router = useRouter()
const navigatelist = [
  { key: 1, value: "玩家" },
  { key: 2, value: "玩家信息" },
  { key: 3, value: "玩家黑名单" },
]
const tablecolumn = reactive([
  {
    prop: "id",
    label: "玩家编号",
    width: "",
  },
  {
    prop: "nickname",
    label: "昵称",
    width: "",
  },
  {
    prop: "statusselect",
    label: "状态",
    width: "",
  },
  {
    prop: "roleselect",
    label: "等级",
    width: "",
  },
])
const tableData = ref([])
const status = ref(undefined)
const role = ref(undefined)

onMounted(() => {
  if (router.currentRoute.value.query.list) {
    const encodedData = JSON.parse(Decrypt(decodeURIComponent(router.currentRoute.value.query.list)));
    console.log(encodedData)
    tableData.value.push(encodedData)
    status.value = tableData.value[0].status
    role.value = tableData.value[0].role
  }
})
const handlecancel = () => {
  router.go(-1)
}
const handleupdate = (item) => {
  oncePress.resTime = 2000
  oncePress.onPress(() => {
    const params = {
      id: tableData.value[0].id,
      status: status.value,
      role: role.value,
    }
    post("/manager/player/modify", params).then(res => {
      if (res.code == 1) {
        console.log(11111)
        ElMessage({
          message: "修改成功",
          type: "success",
        })
        router.go(-1)
      } else {
      }
    })
  })
}

</script>

<style lang="less" scoped>
.PlayerStatus{
  width: 98%;
  padding:10px 10px;
}
.flexbox{
  display: flex;
  align-items: center;
  justify-content: center;
}
.tablewrap{
  margin-top: 20px;
}
.btn{
  margin-top: 20px;
}
</style>
