import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, unref as _unref, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-723809b8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "GameLogList"
};
const _hoisted_2 = {
  style: {
    "margin-top": "20px"
  },
  class: "formhead"
};
const _hoisted_3 = {
  class: "row"
};
const _hoisted_4 = {
  class: "btngroup"
};
const _hoisted_5 = {
  class: "gameNumber"
};
const _hoisted_6 = {
  class: "tablewrap"
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1
};
const _hoisted_9 = {
  key: 2
};
const _hoisted_10 = {
  key: 3
};
const _hoisted_11 = {
  key: 4
};
const _hoisted_12 = ["onClick"];
const _hoisted_13 = ["onClick"];
const _hoisted_14 = ["onClick"];
const _hoisted_15 = {
  key: 8
};
const _hoisted_16 = {
  key: 9
};
const _hoisted_17 = {
  class: "paginationwrap"
};
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router";
import { get, post, exportExcel } from "../../utils/request";
import { baseURL } from "../../utils/global";
import dayjs from "dayjs";
import { Encrypt } from "../../utils/secret";
import { ElMessage } from "element-plus";
export default {
  __name: 'GameRecord',
  setup(__props) {
    const router = useRouter();
    const navigatelist = [{
      key: 1,
      value: "游戏"
    }, {
      key: 2,
      value: "场次信息"
    }];
    const gameStatusSlist = [{
      id: 1,
      name: "进行中"
    }, {
      id: 2,
      name: "正常结束"
    }, {
      id: 3,
      name: "未开始解散"
    }, {
      id: 4,
      name: "进行中解散"
    }];
    const page = ref(1);
    const size = ref(10);
    const total = ref(0);
    const peopleNumber = ref(0);
    const dateSort = ref(0);
    const timeArray = ref([]);
    const gameId = ref(undefined);
    const storeId = ref(undefined);
    const gameStatus = ref(undefined);
    let tableData = ref([]);
    const gamelist = ref([]);
    const storeList = ref([]);
    const tablecolumn = reactive([{
      prop: "id",
      label: "场次编号",
      width: ""
    }, {
      prop: "type",
      label: "类型",
      width: ""
    }, {
      prop: "gameName",
      label: "游戏名称",
      width: ""
    }, {
      prop: "storeName",
      label: "商家名称",
      width: ""
    }, {
      prop: "hostNickname",
      label: "主持人",
      width: ""
    }, {
      prop: "address",
      label: "游戏地点",
      width: "200"
    }, {
      prop: "startTime",
      label: "开始时间",
      width: "200",
      sortable: "custom"
    }, {
      prop: "endTime",
      label: "结束时间",
      width: "200"
    }, {
      prop: "status",
      label: "结束状态",
      width: ""
    }, {
      prop: "players",
      label: "游戏人数",
      width: ""
    }, {
      prop: "winners",
      label: "获胜人数",
      width: ""
    }, {
      prop: "consumePoint",
      label: "消耗点数",
      width: ""
    }]);
    onMounted(() => {
      let record = sessionStorage.getItem('record');
      if (record) {
        record = JSON.parse(record);
        timeArray.value = record.timeArray;
        gameId.value = record.gameId;
        storeId.value = record.storeId;
        gameStatus.value = record.gameStatus;
        page.value = record.page || 1;
        size.value = record.size || 10;
      }
      getlist();
      getGameList();
      getAllStoreList();
    });
    onBeforeUnmount(() => {
      let params = {
        timeArray: timeArray.value,
        gameId: gameId.value,
        storeId: storeId.value,
        gameStatus: gameStatus.value,
        page: page.value,
        size: size.value
      };
      sessionStorage.setItem('record', JSON.stringify(params));
    });
    const handleReset = () => {
      page.value = 1;
      gameId.value = undefined;
      timeArray.value = [];
      storeId.value = undefined;
      gameStatus.value = undefined;
      getlist();
    };
    const handleSearch = () => {
      // if ((timeArray.value && timeArray.value.length > 0) || gameId.value || storeId.value || gameStatus.value || gameStatus.value === 0) {
      //
      // } else {
      // 	if (!document.querySelector(".el-message")) {
      // 		ElMessage.error("请选择搜索条件");
      // 	}
      // }
      getlist();
    };
    const getlist = () => {
      let url = `/manager/game/session/list?page=${page.value}&size=${size.value}`;
      if (timeArray.value && timeArray.value.length > 0) {
        url = url + `&start=${dayjs(timeArray.value[0]).format("YYYY-MM-DD HH:mm:ss")}&end=${dayjs(timeArray.value[1]).format("YYYY-MM-DD HH:mm:ss")}`;
      }
      if (gameId.value !== undefined) {
        url = url + `&gameId=${gameId.value}`;
      }
      if (storeId.value !== undefined) {
        url = url + `&storeId=${storeId.value}`;
      }
      if (dateSort.value) {
        url = url + `&dateSort=${dateSort.value}`;
      }
      if (gameStatus.value || gameStatus.value === 0) {
        url = url + `&gameStatus=${gameStatus.value}`;
      }
      get(url).then(res => {
        // console.log(res);
        if (res.code == 1) {
          tableData.value = res.result.list;
          total.value = res.result.total;
          peopleNumber.value = res.result.peopleNumber;
        }
      });
    };
    const handleCurrentChange = val => {
      page.value = val;
      getlist();
    };
    const handleSizeChange = val => {
      size.value = val;
      getlist();
    };
    const getGameList = () => {
      get(`/manager/game/list`, {}, false).then(res => {
        if (res.code === 1) {
          let list = res.result;
          // list.unshift({
          //     name:'全部',
          //     id:''
          // })
          gamelist.value = list;
        }
      });
    };
    const getAllStoreList = () => {
      get(`/manager/store/name/list`, {}, false).then(res => {
        if (res.code === 1) {
          storeList.value = res.result;
        }
      });
    };
    const handleSortChange = ({
      column,
      prop,
      order
    }) => {
      dateSort.value = 0;
      if (prop == "startTime") {
        if (order == "ascending") {
          // 升序
          dateSort.value = 1;
        } else if (order == "descending") {
          // 降序
          dateSort.value = 2;
        } else {
          dateSort.value = 0;
        }
      }
      getlist();
    };
    const viewDetail = (data, code) => {
      // console.log(data);
      if (code == "detail") {
        let passData = encodeURIComponent(Encrypt(JSON.stringify(data)));
        router.push(`/personStatistics?gameData=${passData}`);
      } else {
        let passData = encodeURIComponent(Encrypt(JSON.stringify(data)));
        router.push(`/gamelog?gameData=${passData}`);
      }
    };
    const downloadAll = () => {
      let url = `/manager/game/session/export?`;
      if (timeArray.value && timeArray.value.length > 0) {
        url = url + `&start=${dayjs(timeArray.value[0]).format("YYYY-MM-DD HH:mm:ss")}&end=${dayjs(timeArray.value[1]).format("YYYY-MM-DD HH:mm:ss")}`;
      }
      if (gameId.value !== undefined) {
        url = url + `&gameId=${gameId.value}`;
      }
      if (storeId.value !== undefined) {
        url = url + `&storeId=${storeId.value}`;
      }
      if (dateSort.value) {
        url = url + `&dateSort=${dateSort.value}`;
      }
      if (gameStatus.value || gameStatus.value === 0) {
        url = url + `&gameStatus=${gameStatus.value}`;
      }
      console.log();
      window.location.href = baseURL + url;
      // exportExcel(url, "GET", {}, "日志详情" + dayjs(Date.now()).format("YYYY-MM-DD"));
    };

    return (_ctx, _cache) => {
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(BreadcrumbVue, {
        navigatelist: navigatelist
      }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_form_item, {
        label: "游戏开始时间"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_date_picker, {
          modelValue: timeArray.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => timeArray.value = $event),
          type: "datetimerange",
          "start-placeholder": "开始时间",
          "end-placeholder": "结束时间"
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "游戏名称"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_select, {
          modelValue: gameId.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => gameId.value = $event),
          placeholder: "请选择游戏名称",
          style: {
            "marginleft": "20px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gamelist.value, item => {
            return _openBlock(), _createBlock(_component_el_option, {
              label: item.name,
              value: item.id,
              key: item.id
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "商家名称"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_select, {
          modelValue: storeId.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => storeId.value = $event),
          placeholder: "请选择商家名称",
          style: {
            "marginleft": "20px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(storeList.value, item => {
            return _openBlock(), _createBlock(_component_el_option, {
              label: item.name,
              value: item.id,
              key: item.id
            }, null, 8, ["label", "value"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "游戏状态"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_select, {
          modelValue: gameStatus.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => gameStatus.value = $event),
          placeholder: "请选择游戏名称",
          style: {
            "marginleft": "20px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(gameStatusSlist, item => {
            return _createVNode(_component_el_option, {
              label: item.name,
              value: item.id,
              key: item.id
            }, null, 8, ["label", "value"]);
          }), 64))]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: downloadAll
      }, {
        default: _withCtx(() => [_createTextVNode("下载全部")]),
        _: 1
      }), _createVNode(_component_el_button, {
        onClick: handleReset
      }, {
        default: _withCtx(() => [_createTextVNode("重置")]),
        _: 1
      }), _createVNode(_component_el_button, {
        type: "primary",
        onClick: handleSearch
      }, {
        default: _withCtx(() => [_createTextVNode("搜索")]),
        _: 1
      })]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, "总计：" + _toDisplayString(total.value) + "场", 1), _createElementVNode("span", null, "，" + _toDisplayString(peopleNumber.value) + "人次", 1)]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_table, {
        data: _unref(tableData),
        onSortChange: handleSortChange,
        style: {
          "width": "100%"
        },
        "empty-text": "还没有数据哦~",
        stripe: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tablecolumn, (item, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            prop: item.prop,
            label: item.label,
            width: item.width,
            sortable: item.sortable
          }, {
            default: _withCtx(scope => [scope.row[item.prop] !== 0 && !scope.row[item.prop] ? (_openBlock(), _createElementBlock("div", _hoisted_7, "-")) : item.prop === 'type' ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(scope.row[item.prop] === 0 ? '正式' : '试玩'), 1)) : item.prop == 'endTime' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", null, _toDisplayString(_unref(dayjs)(scope.row[item.prop]).format("YYYY-MM-DD")), 1), _createElementVNode("div", null, _toDisplayString(_unref(dayjs)(scope.row[item.prop]).format("HH:mm:ss")), 1)])) : item.prop == 'address' ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(scope.row[item.prop] == "[]" ? "未知" : scope.row[item.prop]), 1)) : item.prop == 'startTime' ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", null, _toDisplayString(_unref(dayjs)(scope.row[item.prop]).format("YYYY-MM-DD")), 1), _createElementVNode("div", null, _toDisplayString(_unref(dayjs)(scope.row[item.prop]).format("HH:mm:ss")), 1)])) : item.prop == 'players' ? (_openBlock(), _createElementBlock("div", {
              key: 5,
              class: "hoverclass",
              onClick: $event => viewDetail(scope.row, 'detail')
            }, _toDisplayString(scope.row[item.prop]) + " >> ", 9, _hoisted_12)) : item.prop == 'winners' ? (_openBlock(), _createElementBlock("div", {
              key: 6,
              class: "hoverclass",
              onClick: $event => viewDetail(scope.row, 'detail')
            }, _toDisplayString(scope.row[item.prop]) + " >> ", 9, _hoisted_13)) : item.prop == 'consumePoint' ? (_openBlock(), _createElementBlock("div", {
              key: 7,
              class: "hoverclass",
              onClick: $event => viewDetail(scope.row, 'detail')
            }, _toDisplayString(scope.row[item.prop]) + " >> ", 9, _hoisted_14)) : item.prop == 'status' ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(scope.row[item.prop] == 0 ? "未开始" : scope.row[item.prop] == 1 ? "游戏中" : scope.row[item.prop] == 2 ? "正常结束" : scope.row[item.prop] == 3 ? "未开始解散" : scope.row[item.prop] == 4 ? "游戏中途解散" : ""), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(scope.row[item.prop]), 1))]),
            _: 2
          }, 1032, ["prop", "label", "width", "sortable"]);
        }), 128)), _createVNode(_component_el_table_column, {
          fixed: "right",
          label: "操作",
          width: "185"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            onClick: $event => viewDetail(scope.row, 'log')
          }, {
            default: _withCtx(() => [_createTextVNode("查看详情>>")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])]), _createElementVNode("div", _hoisted_17, [_createVNode(_component_el_pagination, {
        class: "paging fr",
        onCurrentChange: handleCurrentChange,
        "current-page": page.value,
        onSizeChange: handleSizeChange,
        "page-sizes": [10, 20, 50, 100, 200],
        "page-size": size.value,
        "pager-count": 5,
        layout: "total, sizes, prev, pager, next, jumper",
        total: total.value
      }, null, 8, ["current-page", "page-size", "total"])])]);
    };
  }
};