<template>
  <div class="addshop">
    <BreadcrumbVue :navigatelist="navigatelist" />
    <div class="formpart">
      <el-form :model="form" label-width="120px">
        <el-form-item label="商户名称">
          <el-input v-model="form.shopname" placeholder="请输入商户名称" maxlength="16" />
        </el-form-item>
        <el-form-item label="商户城市">
<!--          <el-input v-model="form.shopcity" placeholder="请输入商户城市" maxlength="16" />-->
          <el-cascader style="width: 300px" v-model="form.shopcity" :options="options" :props="props" @change="handleChange" ref="refDep"/>
          <!--					<el-input v-model="form.shopcity" placeholder="请输入商户城市" maxlength="16" />-->
          <!-- <el-select v-model="form.shopcity" placeholder="请输入商户城市">
            <el-option
              v-for="item in citylist"
              :key="item.id"
              :label="item.region"
              :value="Number(item.id)"
            />
          </el-select> -->
        </el-form-item>
        <el-form-item label="商户地址">
          <el-input v-model="form.address" placeholder="请输入商户地址" maxlength="50" />
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="form.phone" placeholder="请输入商户电话" @input="phoneNumberChange" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button @click="oncancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { reactive, onMounted, ref } from "vue";
import { post, get } from "../../utils/request";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { oncePress } from "../../utils/limitClick";

const router = useRouter();

const navigatelist = [
  { key: 1, value: "商家" },
  { key: 2, value: "商家信息" },
  { key: 3, value: router.currentRoute.value.query.id ? "修改商家" : "新增商家" },
];
const citylist = ref([]);
const form = reactive({
  shopname: "",
  shopcity: "",
  address: "",
  phone: "",
});
onMounted(() => {
  if (router.currentRoute.value.query.id) {
    getinformation();
  }
  getcitylist();
  getDistrict()
});
let options = ref([])
const props = {
  label: "name",
  children: "children",
  value: "id"
}
const getDistrict = async () => {
  const { code, result } = await get(`/manager/region/lazy-list`);
  if (code != 1) return;
  options.value = result;
};
const getinformation = () => {
  get(`/manager/store/detail?id=${router.currentRoute.value.query.id}`).then((res) => {
    if (res.code == 1) {
      form.shopname = res.result.name;
      // 换字符串
      form.shopcity = res.result.regionId;
      form.address = res.result.address;
      form.phone = res.result.phone;
      regionName.value = res.result.region;
    }
  });
};
const refDep = ref(null)
const regionName = ref('')
const handleChange = (e) => {
  regionName.value = refDep.value.getCheckedNodes()[0].pathLabels.join(',')
}
const getcitylist = () => {
  get("/app/region?value=").then((res) => {
    if (res.code == 1) {
      citylist.value = res.result;
    }
  });
};
const phoneNumberChange = (val) => {
  console.log(val);
  let filteredValue = val.replace(/[^0-9\s+]/g, "");
  if (filteredValue.length > 20) {
    filteredValue = filteredValue.slice(0, 20);
  }
  form.phone = filteredValue;
};
const onSubmit = () => {
  oncePress.resTime = 2000;
  oncePress.onPress(() => {
    let param = {
      name: form.shopname,
      regionId: encodeURIComponent(Array.isArray(form.shopcity)?form.shopcity[form.shopcity.length - 1]:form.shopcity),
      address: form.address,
      phone: form.phone,
      region: regionName.value
    };
    if (router.currentRoute.value.query.id) {
      param.id = router.currentRoute.value.query.id;
    }
    post("/manager/store", param).then((res) => {
      if (res.code == 1) {
        if (router.currentRoute.value.query.id) {
          ElMessage({
            message: "修改成功",
            type: "success",
          });
        } else {
          ElMessage({
            message: "新增成功",
            type: "success",
          });
        }
        router.go(-1);
      }
    });
  });
};
const oncancel = () => {
  router.go(-1);
};
</script>

<style lang="less" scoped>
.addshop {
  width: 98%;
  padding: 10px;
}

.formpart {
  margin-top: 30px;
}

.el-input {
  width: 300px;
}
</style>

