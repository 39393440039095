<template>
  <div class="PlayerStatus">
    <BreadcrumbVue :navigatelist="navigatelist"/>
    <div class="tablewrap">
      <el-table :data="tableData" @sort-change="handleSortChange" style="width: 100%" empty-text="还没有数据哦~" stripe>
        <el-table-column
            v-for="(item, index) in tablecolumn" :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="item.prop=='roleselect'">
              <el-select v-model="role" placeholder="请选择等级">
                <el-option
                    label="见习主持人"
                    value="noviciate"
                />
                <el-option
                    label="认证主持人"
                    value="authentication"
                />
                <el-option
                    label="1星主持人"
                    value="one_star"
                />
                <el-option
                    label="2星主持人"
                    value="two_star"
                />
                <el-option
                    label="3星主持人"
                    value="three_star"
                />
              </el-select>
            </div>
            <div v-else-if="item.prop=='ownership'">
              <el-select v-model="hostId" placeholder="请选择归属" filterable>
                <el-option
                    v-for="item in shopList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
            <div v-else-if="item.prop=='username'">
              {{scope.row[item.prop]?scope.row[item.prop]:'微信登陆'}}
            </div>
            <div
                v-else-if="scope.row[item.prop]!=0&&!scope.row[item.prop]"
            >-
            </div>
            <div v-else>{{scope.row[item.prop]}}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="btn">
      <el-button type="primary" @click="showSubmit = true">确定</el-button>
      <el-button  @click="handlecancel">取消</el-button>
    </div>
    <el-dialog
        title="确认信息"
        v-model="showSubmit"
        width="30%"
    >
      <span>请确认填写信息是否正确</span>
      <template #footer>
			<span class="dialog-footer">
				<el-button @click="showSubmit = false">取消</el-button>
				<el-button type="primary" @click="submit"> 确定 </el-button>
			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue"
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router"
import { get, post } from "../../utils/request"
import { ElMessage } from "element-plus"
import { Decrypt } from "../../utils/secret"
import { oncePress } from "../../utils/limitClick"
const router = useRouter()
const navigatelist = ref([])
const tablecolumn = reactive([
  {
    prop: "id",
    label: "主持人ID",
    width: "",
  },
  {
    prop: "nickname",
    label: "名称",
    width: "",
  },
  {
    prop: "username",
    label: "登录账号",
    width: "",
  },
  {
    prop: "notes",
    label: "备注",
    width: "",
  },
  {
    prop: "roleselect",
    label: "主持人等级等级",
    width: "",
  },
  {
    prop: "ownership",
    label: "归属",
    width: "",
  },
])
const tableData = ref([])
const status = ref(undefined)
const role = ref(undefined)
const hostId = ref(undefined)
const showSubmit = ref(false);
onMounted(() => {
  if (router.currentRoute.value.query.list) {
    const encodedData = JSON.parse(Decrypt(decodeURIComponent(router.currentRoute.value.query.list)));
    const type = router.currentRoute.value.query.type
    tableData.value.push(encodedData)
    role.value = encodedData.hostGrade
    hostId.value = encodedData.storeId ? encodedData.storeId.toString() : ""
    if (type === "store") {
      navigatelist.value = [
        { key: 1, value: "商家" },
        { key: 1, value: "商家信息" },
        { key: 2, value: "主持人" },
        { key: 3, value: "商家状态" },
      ]
    } else {
      navigatelist.value = [
        { key: 1, value: "商家" },
        { key: 2, value: "主持人" },
        { key: 3, value: "商家状态" },
      ]
    }
  }
  getShopList()
})
const shopList = ref([])
// 获取所有商家
const getShopList = () => {
  const url = `/manager/store/list?page=${1}&size=${1000}`
  get(url).then((res) => {
    if (res.code === 1) {
      shopList.value = res.result.list;
    }
  });
}
const handlecancel = () => {
  router.go(-1)
}
// 提交
const submit = () => {
  showSubmit.value = false
  oncePress.resTime = 2000
  oncePress.onPress(async () => {
    if (!hostId.value) {
      return ElMessage({
        message: "请选择主持人归属",
        type: "error",
      })
    }
    if (role.value) {
      const { code: codeGrade } = await post(`/manager/host/setHostGrade?hostGrade=${role.value}&hostId=${tableData.value[0].id}`)
      if (codeGrade != 1) return
    }
    const { code: codeSet } = await post(`/manager/host/setHost?hostId=${tableData.value[0].id}&storeId=${hostId.value}`)
    if (codeSet != 1) return
    ElMessage({
      message: "设置成功",
      type: "success",
    })
    setTimeout(() => {
      router.go(-1)
    })
  })
}
</script>

<style lang="less" scoped>
.PlayerStatus{
  width: 98%;
  padding:10px 10px;
}
.flexbox{
  display: flex;
  align-items: center;
  justify-content: center;
}
.tablewrap{
  margin-top: 20px;
}
.btn{
  margin-top: 20px;
}
</style>
