<template>
  <div class="PlayerList">
    <BreadcrumbVue :navigatelist="navigatelist" />
    <div style="margin-top: 20px" class="formhead">
      <div class="row">
        <el-form-item label="主持人ID">
          <el-input v-model="id" placeholder="请输入主持人ID" />
        </el-form-item>
      </div>
    </div>
    <div class="btngroup">
      <el-button @click="isClearLogin = false">重置</el-button>
      <el-button type="primary" @click="handleSearch">确认搜索</el-button>
    </div>
    <div class="tablewrap">
      <el-table
          :data="tableData"
          @sort-change="handleSortChange"
          style="width: 100%"
          empty-text="还没有数据哦~"
          stripe
      >
        <el-table-column
            v-for="(item, index) in tablecolumn"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop] && item.prop != 'hostGrade' && item.prop != 'storeId'">-</div>
            <div v-else-if="item.prop == 'createTime'">
              {{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
            <div
                class="hoverclass"
                v-else-if="item.prop == 'hostGrade'"
                @click="handleupdate(scope.row, 'status')"
            >
              {{
                scope.row[item.prop]?
                    scope.row[item.prop] === 'noviciate'?'见习主持人':
                        scope.row[item.prop] === 'authentication'?
                            '认证主持人':scope.row[item.prop] === 'one_star' ?
                                '1星主持人':scope.row[item.prop] === 'two_star'?
                                    '2星主持人':'3星主持人':'前往设置'}} >>
            </div>
            <div v-else-if="item.prop == 'storeId'"
                 class="hoverclass"
                 @click="handleupdate(scope.row, 'status')"
            >
              {{scope.row[item.prop]?scope.row.storeName:'前往设置'}} > >
            </div>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationwrap">
      <el-pagination
          class="paging fr"
          @current-change="handleCurrentChange"
          :current-page="page"
          @size-change="handleSizeChange"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="size"
          :pager-count="5"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router";
import { get, post } from "../../utils/request";
import dayjs from "dayjs";
import { Encrypt } from "../../utils/secret";
const router = useRouter();
const navigatelist = [
  { key: 1, value: "商家" },
  { key: 2, value: "主持人" },
];
const isClearLogin = ref(false)
const page = ref(1);
const size = ref(10);
const total = ref(0);
const dateSort = ref(0);
const integralSort = ref(0);
const id = ref(undefined);
const timeArray = ref([]);
const status = ref(undefined);
const role = ref(undefined);
const seasonList = ref([]);
const seasonId = ref("");
// const defaultTime = ref<[Date, Date]>([
//   new Date(2000, 1, 1, 0, 0, 0),
//   new Date(2000, 2, 1, 23, 59, 59),
// ])
const tableData = ref([]);
const tablecolumn = reactive([
  {
    prop: "id",
    label: "主持人ID",
    width: "",
  },
  {
    prop: "nickname",
    label: "昵称",
    width: "",
  },
  {
    prop: "createTime",
    label: "申请时间",
    width: "",
    sortable: "custom",
  },
  {
    prop: "hostGrade",
    label: "等级",
    width: "",
  },
  {
    prop: "storeId",
    label: "归属",
    width: "",
  },
]);
onMounted(() => {
  getlist();
  getSeasonlist();
});
const getSeasonlist = () => {
  const url = "/admin/seasonManagement/list";
  get(url).then((res) => {
    console.log("get seasonManagement->", res);
    if (res.code === 1) {
      seasonList.value = res.result;
    }
  });
};
const handleSearch = () => {
  getlist();
};
const handleReset = () => {
  id.value = undefined;
  timeArray.value = [];
  status.value = undefined;
  role.value = undefined;
  seasonId.value = undefined;
  getlist();
};
const handleupdate = (item) => {
  const list = encodeURIComponent(Encrypt(JSON.stringify(item)));
  router.push(`/ownershipStatus?list=${list}&type=list`);
};
const getlist = () => {
  let url = `/manager/host/new/list?page=${page.value}&size=${size.value}`;
  if (timeArray.value.length > 0) {
    url =
        url +
        `&start=${dayjs(timeArray.value[0]).format("YYYY-MM-DD HH:mm:ss")}&end=${dayjs(
            timeArray.value[1],
        ).format("YYYY-MM-DD HH:mm:ss")}`;
  }
  if (id.value) {
    url = url + `&id=${id.value}`;
  }
  if (status.value !== undefined) {
    url = url + `&status=${status.value}`;
  }
  if (role.value !== undefined) {
    url = url + `&role=${role.value}`;
  }
  if (seasonId.value !== undefined) {
    url = url + `&seasonId=${seasonId.value + ""}`;
  }
  if (dateSort.value) {
    url = url + `&dateSort=${dateSort.value}`;
  }
  if (integralSort.value) {
    url = url + `&integralSort=${integralSort.value}`;
  }
  get(url).then((res) => {
    if (res.code == 1) {
      res.result.list.forEach(res => {
        for (const item in res.characterDto) {
          res[item] = res.characterDto[item]
        }
      })
      tableData.value = res.result.list;
      total.value = res.result.total;
    }
  });
};
const handleCurrentChange = (val) => {
  page.value = val;
  getlist();
};
const handleSizeChange = (val) => {
  size.value = val;
  getlist();
};
const handleSortChange = ({ column, prop, order }) => {
  dateSort.value = 0;
  integralSort.value = 0;
  if (prop == "createTime") {
    if (order == "ascending") {
      // 升序
      dateSort.value = 1;
    } else if (order == "descending") {
      // 降序
      dateSort.value = 2;
    } else {
      dateSort.value = 0;
    }
  } else if (prop == "integral") {
    if (order == "ascending") {
      // 升序
      integralSort.value = 1;
    } else if (order == "descending") {
      // 降序
      integralSort.value = 2;
    } else {
      integralSort.value = 0;
    }
  }
  getlist();
};
</script>

<style lang="less" scoped>
.PlayerList {
  width: 98%;
  padding: 10px 10px;
}
.flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btngroup {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.tablewrap {
  margin-top: 10px;
}
.paginationwrap {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-end;
}
.hoverclass {
  cursor: pointer;
}
.hoverclass:hover {
  color: var(--el-color-primary);
}
.el-input {
  width: 200px;
}
.formhead {
  .row {
    display: flex;
    > div {
      margin-left: 20px;
    }
    > div:first-child {
      margin-left: 0px;
    }
  }
  /deep/ .el-range-editor.el-input__wrapper {
    width: 350px;
  }
}
</style>
