<template>
  <div class="boss letterOfInvitation">
    <BreadcrumbVue :navigatelist="navigatelist" />
    <div class="search">
      <div class="row">
        <el-form-item label="邀请函名称">
          <el-input v-model="invitationName" placeholder="请输入邀请涵名称" />
        </el-form-item>
      </div>
      <div class="btngroup">
        <el-button type="primary" @click="showEditing('add')">创建邀请函</el-button>
        <el-button>重置</el-button>
        <el-button type="primary" @click="getList">确认搜索</el-button>
      </div>
    </div>
    <div class="table">
      <el-table
          :data="list"
          style="width: 100%"
          empty-text="还没有数据哦~"
          stripe
      >
        <el-table-column
            v-for="(item, index) in listTitle"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="item.prop === 'totalQuantity' || item.prop === 'giftedQuantity' || item.prop === 'usedQuantity'" @click="goRecord(scope.row, item.label, item.prop)">{{scope.row[item.prop] || 0}} >>></div>
            <div v-else-if="item.prop === 'invitationType'">{{scope.row[item.prop] == 1?'赠送':'自用'}}</div>
            <div v-else-if="item.prop === 'usedQuantityTwo'">{{scope.row.usedQuantity > scope.row.useableNext?(scope.row.useableNext / scope.row.usedQuantity).toFixed(2) : 0}}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
            <template  #default="scope">
              <el-button link type="primary" @click="goDistribution(scope.row)">发放></el-button>
              <el-button link type="danger" @click="deleteInvitations(scope.row)">删除</el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <div class="paginationwrap">
      <el-pagination
          class="paging fr"
          @current-change="handleCurrentChange"
          :current-page="page"
          @size-change="handleSizeChange"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="size"
          :pager-count="5"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--编辑/创建邀请函-->
    <el-dialog
        title="创建邀请函"
        v-model="isEditing"
        width="800px"
        :before-close="cancelEditing">
      <el-form :model="editingData" :rules="editingRules" ref="editForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="邀请函名称" prop="invitationName">
          <el-input v-model="editingData.invitationName" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="使用次数" prop="useableNext">
          <el-input v-model="editingData.useableNext" type="number" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="起始日" prop="startDate">
          <el-date-picker
              style="width: 100%"
              v-model="editingData.startDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="到期日" prop="expirationDate">
          <el-date-picker
              style="width: 100%"
              v-model="editingData.expirationDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="类型" prop="invitationType">
          <el-select v-model="editingData.invitationType" placeholder="请选择类型" style="width: 100%">
            <el-option label="自用" :value="0"></el-option>
            <el-option label="赠送" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="背景图" prop="backgroundImage" style="width: 100%">
          <div style="display: flex;align-items: flex-start;width: 100%">
            <el-input v-model="editingData.backgroundImage" style="margin-right: 20px;width: 100%"></el-input>
            <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
            >
              <img v-if="editingData.backgroundImage" :src="editingData.backgroundImage" class="avatar">
              <i v-else class="el-icon-close avatar-uploader-icon"><Plus  style="width: 24px; height: 24px;margin-top: 5.5px"/></i>
            </el-upload>
            <el-button type="primary" style="margin-left: 20px" @click="showImages">选择背景图</el-button>
          </div>
        </el-form-item>
        <el-form-item label="内容" style="width: 100%"  prop="invitationContent">
          <el-input type="textarea" v-model="editingData.invitationContent" maxlength="50"></el-input>
        </el-form-item>
      </el-form>
      <div class="but">
        <el-button @click="cancelEditing">取 消</el-button>
        <el-button type="primary" @click="editingSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="选择邀请函背景图"
        v-model="isImages"
        width="1000px">
      <div class="imgPop">
        <div :class="backgroundImgIndex === index?'item items':'item'" v-for="(item, index) in backgroundImg" :key="index" @click="backgroundImgIndex = index">
          <img class="img" :src="item" alt="" />
        </div>
      </div>
      <div class="but">
        <el-button @click="isImages = false">取 消</el-button>
        <el-button type="primary" @click="backgroundImgSelect">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        v-model="isDelete"
        title="温馨提示"
        width="500"
    >
      <span>您确定要删除 <text style="color: #409EFF;">“{{deleteData.invitationName}}”</text> 邀请函吗？该操作无法撤销！</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="isDelete = false">取消</el-button>
          <el-button type="primary" @click="deleteSubmit">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { reactive, onMounted, ref } from "vue";
import { post, get } from "../../utils/request";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { Encrypt } from "@/utils/secret";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import dayjs from "dayjs";
onMounted(() => { getList() });
const router = useRouter();
const navigatelist = ref([
  { key: 1, value: "特殊道具" },
  { key: 2, value: "邀请函管理" },
]);
// 表格数据
const invitationName = ref(undefined)
const listTitle = reactive([
  { label: "批次号", prop: "batchNumber", width: "100px" },
  { label: "邀请函名称", prop: "invitationName", width: "" },
  { label: "类型", prop: "invitationType", width: "80px" },
  { label: "可用次数", prop: "useableNext", width: "100px" },
  { label: "邀请函内容", prop: "invitationContent", width: "" },
  { label: "起始日", prop: "startDate", width: "" },
  { label: "到期日", prop: "expirationDate", width: "" },
  { label: "发放数量", prop: "totalQuantity", width: "80px" },
  { label: "赠送数量", prop: "giftedQuantity", width: "80px" },
  { label: "使用次数", prop: "usedQuantity", width: "100px" },
])
const list = ref([])
const getList = async () => {
  let url = `/admin/Tinvitation/page?current=${page.value}&size=${size.value}`
  if (invitationName.value !== undefined) url += `&invitationName=${invitationName.value}`
  const { result } = await get(url)
  list.value = result.records
  total.value = result.total
}
// 翻页数据
const page = ref(1);
const size = ref(10);
const total = ref(0);
const handleCurrentChange = (val) => {
  page.value = val;
  getList()
}
const handleSizeChange = (val) => {
  size.value = val;
  getList();
}
// 编辑/创建邀请函
const isEditing = ref(false)
const editForm = ref(null)
const editingRules = reactive({
  invitationName: [
    { required: true, message: "请输入邀请函名称", trigger: "blur" },
  ],
  invitationType: [
    { required: true, message: "请选择邀请函类型", trigger: "blur" },
  ],
  expirationDate: [
    { required: true, message: "请选择到期日期", trigger: "blur" },
  ],
  startDate: [
    { required: true, message: "请选择起始日期", trigger: "blur" },
  ],
  backgroundImage: [
    { required: true, message: "请上传背景图", trigger: "blur" },
  ],
  useableNext: [
    { required: true, message: "请使用次数", trigger: "blur" },
  ],
  invitationContent: [
    { required: true, message: "请输入邀请函描述内容", trigger: "blur" },
  ],
})
const editingData = reactive({
  invitationName: "",
  invitationType: "",
  expirationDate: "",
  backgroundImage: "",
  invitationContent: "",
  // batchNumber: "ABCD888",
  useableNext: "",
  startDate: "",
})
const isImages = ref(false)
const backgroundImg = ref([
  "https://cdn.thejokergame.cn/assets/invitationLetterOne.png",
  "https://cdn.thejokergame.cn/assets/invitationLetterTwo.png",
  "https://cdn.thejokergame.cn/assets/invitationLetterThree.png",
  "https://cdn.thejokergame.cn/assets/invitationLetterFour.png",
  "https://cdn.thejokergame.cn/assets/invitationLetterFive.png",
  "https://cdn.thejokergame.cn/assets/invitationLetterSix.png",
  "https://cdn.thejokergame.cn/assets/invitationLetterSeven.png",
  "https://cdn.thejokergame.cn/assets/invitationLetterEight.png",
  "https://cdn.thejokergame.cn/assets/invitationLetterNine.png",
  "https://cdn.thejokergame.cn/assets/invitationLetterTen.png"])
const backgroundImgIndex = ref(null)
const showEditing = () => {
  isEditing.value = true
}
const showImages = () => {
  if (editingData.backgroundImage) {
    backgroundImg.value.forEach((res, ind) => {
      if (res === editingData.backgroundImage) backgroundImgIndex.value = ind
    })
  }
  isImages.value = true
}
const backgroundImgSelect = () => {
  editingData.backgroundImage = backgroundImg.value[backgroundImgIndex.value]
  isImages.value = false
  backgroundImgIndex.value = null
}
// 取消编辑
const cancelEditing = () => {
  isEditing.value = false
  for (let item in editingData) {
    editingData[item] = ""
  }
}
// 提交编辑
const editingSubmit = () => {
  editForm.value.validate(async (valid) => {
    if (valid) {
      let editingDatas = editingData
      editingDatas.expirationDate = dayjs(editingData.expirationDate).format("YYYY-MM-DD HH:mm:ss")
      editingDatas.startDate = dayjs(editingData.startDate).format("YYYY-MM-DD HH:mm:ss")
      const {code, message} = await post("/admin/Tinvitation/creatSave", editingDatas)
      if (code !== 1) return ElMessage.error(message)
      getList()
      isEditing.value = false
    }
  });
}
// 上传背景图
const handleAvatarSuccess = (res, file) => {
  editingData.url = URL.createObjectURL(file.raw);
}
// 前往记录
const goRecord = (data, label, prop) => {
  data.label = label
  data.prop = prop
  const passData = encodeURIComponent(Encrypt(JSON.stringify(data)));
  router.push(`/record?data=${passData}`);
}
// 前往发放邀请函
const goDistribution = (data) => {
  const passData = encodeURIComponent(Encrypt(JSON.stringify(data)));
  router.push(`/distribution?data=${passData}`);
}
// 删除邀请函
const isDelete = ref(false)
let deleteData = reactive({})
const deleteInvitations = (data) => {
  isDelete.value = true
  deleteData = data
}
const deleteSubmit = async () => {
  const {result} = await post(`/admin/Tinvitation/delete?id=${deleteData.id}`)
  isDelete.value = false
  getList()
  ElMessage({
    message: "删除成功！",
    type: "success",
  })
}
</script>
<style lang="less" scoped>
.boss {
  padding: 20px;
  width: 100%;

  .search {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    .btngroup {
      margin-left: 20px;
    }
  }

  .paginationwrap {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-end;
  }

  .demo-ruleForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 50%;
    }
  }

  .but {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .imgPop {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .item {
      margin: 10px 10px 0 0;
      width: 300px;
      box-sizing: border-box;

      .img {
        width: 100%;
        display: block;
      }
    }

    .items {
      border: 4px solid #409eff;
      box-shadow: 0 0 5px #999;
    }
  }
}
</style>

