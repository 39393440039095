<template>
	<el-container style="height: calc(100% - 93px)">
		<el-aside width="200px">
			<el-scrollbar>
				<el-menu :default-active="defaultopen">
					<el-menu-item
						:index="bar.index"
						v-for="bar in firstBarData"
						:key="bar.index"
						@click="navigateto(bar.url)"
					>
						{{ bar.title }}
					</el-menu-item>
					<el-sub-menu v-for="bar in leftBarData" :index="bar.index" :key="bar.index">
						<template #title>
							{{ bar.title }}
						</template>
						<el-menu-item-group>
							<el-menu-item
								v-for="subs in bar.subs"
								:index="subs.index"
								:key="subs.index"
								@click="navigateto(subs.url)"
							>
								{{ subs.title }}
							</el-menu-item>
						</el-menu-item-group>
					</el-sub-menu>
				</el-menu>
			</el-scrollbar>
		</el-aside>
		<el-container>
			<router-view></router-view>
		</el-container>
	</el-container>
</template>

<script setup>
import { reactive, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const navigateto = (url) => {
	router.push(url);
};
const defaultopen = ref("0");
const firstBarData = reactive([
	{
		index: "0",
		title: "首页",
		url: "homepage",
		subs: [],
	},
]);
const leftBarData = reactive([
	{
		index: "1",
		title: "游戏",
		subs: [
			{
				index: "1-1",
				title: "场次信息",
				url: "gamerecord",
				// class: 'iconfont iconshangpingouwudai2'
			},
			{
				index: "1-2",
				title: "游戏设置",
				url: "pointset",
				// class: 'iconfont iconshangpingouwudai2'
			},
      // {
      //   index: "1-3",
      //   title: "游戏管理",
      //   url: "gamemanage"
      // },
		],
	},
	{
		index: "2",
		title: "玩家",
		subs: [
			{
				index: "2-1",
				title: "玩家信息",
				url: "playerlist",
				// class: 'iconfont iconshangpingouwudai2'
			},
			{
				index: "2-2",
				title: "玩家反馈",
				url: "playerfeedback",
			},
		],
	},
	{
		index: "3",
		title: "商家",
		subs: [
			{
				index: "3-1",
				title: "商家信息",
				url: "shoplist",
				// class: 'iconfont iconshangpingouwudai2'
			},
			{
				index: "3-2",
				title: "游戏点数",
				url: "gamepointList",
			},
      {
        index: "3-3",
        title: "地区管理",
        url: "district",
      },
      {
        index: "3-4",
        title: "主持人",
        url: "groupingList",
      },
		],
	},
	{
		index: "4",
		title: "系统",
		subs: [
			{
				index: "4-1",
				title: "操作记录",
				url: "operatorView",
				// class: 'iconfont iconshangpingouwudai2'
			},
			{
				index: "4-2",
				title: "账号相关",
				url: "accountView",
			},
		],
	},
  {
    index: "5",
    title: "赛季",
    subs: [
      {
        index: "5-1",
        title: "赛季管理",
        url: "seasonView",
      }
    ],
  },
  {
    index: "6",
    title: "特殊道具",
    subs: [
      {
        index: "6-1",
        title: "邀请函管理",
        url: "letterOfInvitation",
      },
      {
        index: "6-2",
        title: "J-Dice管理",
        url: "JDice",
      }
    ],
  },
]);
onMounted(() => {
	leftBarData.forEach((item) => {
		if (item.subs) {
			item.subs.forEach((ite) => {
				if (window.location.href.includes(ite.url)) {
					defaultopen.value = ite.index;
				}
			});
		}
	});
});
watch(
	() => router.currentRoute.value,
	(newval, oldval) => {
		leftBarData.forEach((item) => {
			if (item.subs) {
				item.subs.forEach((ite) => {
					if (window.location.href.includes(ite.url)) {
						defaultopen.value = ite.index;
					}
				});
			}
		});
	}
);
</script>

<style scoped lang="less">
.el-aside {
	height: 100%;
}
a {
	text-decoration-line: none;
}
.el-container {
	> div:first-child {
		overflow: auto;
	}
}
</style>
